<template>
  <div class="login-bg">
    <div class="login-container">
      <router-link to="/">
        <img src="/img/logo_bike.svg" height="61" />
      </router-link>
      <div class="card mt-3">
        <div class="card-body">
          <strong>Faça login em sua conta</strong>
          <LoginForm @login-success="onLoginSuccess" />
          <hr />
          <strong>Ainda não possui uma conta?</strong>
          <router-link
            :to="getRotaCriarConta()"
            class="btn btn-outline-primary btn-block mt-2"
          >Criar uma nova conta</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "../components/Login/LoginForm";

export default {
  components: {
    LoginForm
  },
  methods: {
    onLoginSuccess() {
      this.loginRedirect();
    },
    loginRedirect() {
      var returnUrl = this.$route.query.returnUrl || "/";      
      this.$router.replace(decodeURIComponent(returnUrl));     
    },
    getRotaCriarConta(){
        var returnUrl = this.$route.query.returnUrl || "/";
        return '/criar-conta?returnUrl=' + returnUrl;
    }
  }
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: 12px;
  padding-top: 7vh;
}

.login-bg {
  background-color: #1c1c1b;
  background-image: url(/img/fundo_home.jpg);
  background-size: cover;
  min-height: 100vh;
}
</style>